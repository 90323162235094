<template>
  <div class="main_wrapper">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ $t('portal.request_duplicate_certificate') }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item active"><a to="#">{{ $t('portal.request_duplicate_certificate') }}</a></li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="menu_bar_wrapper">
      <b-container>
        <b-row>
          <b-col sm="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('portal.request_duplicate_certificate') }}</h4>
              </template>
              <template v-slot:body>
                <div class="all_service_wrapper venue">
                  <b-overlay :show="load">
                    <div>
                      <b-alert show dismissible variant="success" v-if="showMessage">
                        {{$t('globalTrans.we_notify_you_in_message')}} <b>&rArr;</b>
                      </b-alert>
                    </div>
                    <b-row>
                      <b-col lg="12" md="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                              <b-row>
                                <b-col lg="12" md="12">
                                <b-overlay :show="isLoadingData">
                                  <fieldset class="p-2 w-100">
                                    <legend class="px-2 w-50 shadow-sm">{{$t('elearning_iabm.applicant_info')}}</legend>
                                    <b-row class="p-3">
                                      <b-col lg="6" sm="6">
                                          <ValidationProvider name="Subject" vid="subject_type_id" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="subject_type_id"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.subject')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                      plain
                                                      v-model="formData.subject_type_id"
                                                      :disabled="checkDisable('subject_type_id')"
                                                      :options="subjectTypeList"
                                                      id="subject_type_id"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                  >
                                                  <template v-slot:first>
                                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                  </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12">
                                        <ValidationProvider name="Name (En)" vid='name_en' rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="name_en"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('user.name')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="name_en"
                                                v-model="formData.name_en"
                                                :disabled="checkDisable('name_en')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12">
                                        <ValidationProvider name="Name (bn)" vid='name_bn' rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="name_bn"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('user.name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="name_bn"
                                                v-model="formData.name_bn"
                                                :disabled="checkDisable('name_bn')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12">
                                        <ValidationProvider name="Mobile" vid='mobile' rules="required|min:11|max:11">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="mobile"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('globalTrans.mobile')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                              id="mobile"
                                              v-model="formData.mobile"
                                              :disabled="checkDisable('mobile')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12">
                                        <ValidationProvider name="Email" vid='email' rules="required|email|min:3">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="email"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('externalTraining.email')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="email"
                                                v-model="formData.email"
                                                :disabled="checkDisable('email')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                    </b-row>
                                  </fieldset>
                                  <fieldset class="p-2 w-100">
                                    <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.professional_info')}}</legend>
                                    <b-row class="p-3">
                                      <b-col lg="6" sm="12">
                                        <ValidationProvider name="Profession Type" vid="profession_type" rules="required|min_value:1">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="profession_type"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_iabm.profession_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.profession_type"
                                                :disabled="checkDisable('profession_type')"
                                                :options="professionTypeList"
                                                id="profession_type"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                              <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.profession_type === 1 || formData.profession_type === 2">
                                        <ValidationProvider name="Job Type" vid="job_type_id" rules="required|min_value:1">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="job_type_id"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_iabm.job_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.job_type_id"
                                                :disabled="checkDisable('job_type_id')"
                                                :options="jobTypeList"
                                                id="job_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                              <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.job_type_id == 1 && (formData.profession_type == 1 ||  formData.profession_type == 2)">
                                        <ValidationProvider name="Cader Name (En)" vid='cader_name' rules="">
                                          <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="cader_name"
                                            slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_iabm.cader_name')}} {{ $t('globalTrans.enn')}}
                                            </template>
                                            <b-form-input
                                              id="cader_name"
                                              v-model="formData.cader_name"
                                              :disabled="checkDisable('cader_name')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.job_type_id == 1 && (formData.profession_type == 1 ||  formData.profession_type == 2)">
                                        <ValidationProvider name="Cader Name (Bn)" vid='cader_name_bn' rules="">
                                          <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="cader_name_bn"
                                            slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_iabm.cader_name')}} {{ $t('globalTrans.bnn')}}
                                            </template>
                                            <b-form-input
                                              id="cader_name_bn"
                                              v-model="formData.cader_name_bn"
                                              :disabled="checkDisable('cader_name_bn')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.job_type_id == 1 && (formData.profession_type == 1 ||  formData.profession_type == 2)">
                                        <ValidationProvider name="Batch" vid="batch" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="batch"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{ $t('elearning_iabm.batch')}}
                                                </template>
                                                <b-form-input
                                                    id="batch"
                                                    v-model="formData.batch"
                                                    :disabled="checkDisable('batch')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.profession_type == 2 || formData.profession_type == 3">
                                        <ValidationProvider name="Organization Name (En)" vid='other_org_name' rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="other_org_name"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="other_org_name"
                                                v-model="formData.other_org_name"
                                                :disabled="checkDisable('other_org_name')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.profession_type == 2 || formData.profession_type == 3">
                                        <ValidationProvider name="Organization Name (Bn)" vid='other_org_name_bn' rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="other_org_name_bn"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="other_org_name_bn"
                                                v-model="formData.other_org_name_bn"
                                                :disabled="checkDisable('other_org_name_bn')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.profession_type == 1">
                                        <ValidationProvider name="Organization" vid="region_id" rules="required|min_value:1">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="org_id"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('externalUserIrrigation.organization')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.org_id"
                                                :disabled="checkDisable('org_id')"
                                                :options="orgList"
                                                id="org_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                              <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="6" v-if="formData.profession_type == 1">
                                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="office_type_id"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                      plain
                                                      v-model="formData.office_type_id"
                                                      :disabled="checkDisable('office_type_id')"
                                                      :options="officeTypeList"
                                                      id="office_type_id"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                  >
                                                  <template v-slot:first>
                                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                  </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="6" v-if="formData.profession_type == 1">
                                        <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.office_id"
                                                    :disabled="checkDisable('office_id')"
                                                    :options="officeList"
                                                    id="office_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="12" sm="12" v-if="formData.profession_type == 1" class="mb-3">
                                          <b-form-checkbox
                                              class="mt-2"
                                              id="not_here_office"
                                              v-model="formData.not_here_office"
                                              :disabled="checkDisable('not_here_office')"
                                              :value=1
                                              :unchecked-value=0
                                          >
                                            <span class="text-danger">{{$t('elearning_iabm.not_here_office')}}</span>
                                          </b-form-checkbox>
                                      </b-col>
                                      <b-col lg="6" sm="12"  v-if="formData.not_here_office == 1 && formData.profession_type == 1">
                                        <ValidationProvider name="Office Name (En)" vid='office_name' rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="office_name"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_iabm.office_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="office_name"
                                                v-model="formData.office_name"
                                                :disabled="checkDisable('office_name', 'professional_info')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.not_here_office == 1 && formData.profession_type == 1">
                                        <ValidationProvider name="Office Name (Bn)" vid='office_name_bn' rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="office_name_bn"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_iabm.office_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="office_name_bn"
                                                v-model="formData.office_name_bn"
                                                :disabled="checkDisable('office_name_bn', 'professional_info')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.profession_type == 1">
                                        <ValidationProvider name="Office Email" vid='office_mail_address'>
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="office_mail_address"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('externalTraining.office_mail')}} {{ $t('globalTrans.enn') }}
                                            </template>
                                            <b-form-textarea
                                                id="office_mail_address"
                                                v-model="formData.office_mail_address"
                                                :disabled="checkDisable('office_mail_address')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.profession_type == 1">
                                        <ValidationProvider name="Office Email" vid='office_mail_address_bn'>
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="office_mail_address_bn"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('externalTraining.office_mail')}} {{ $t('globalTrans.bnn') }}
                                            </template>
                                            <b-form-textarea
                                                id="office_mail_address_bn"
                                                v-model="formData.office_mail_address_bn"
                                                :disabled="checkDisable('office_mail_address_bn')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.profession_type == 1">
                                        <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="designation_id"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_tim.designation')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.designation_id"
                                                :disabled="checkDisable('designation_id')"
                                                :options="designationList"
                                                id="designation_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                              <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="12" sm="12" v-if="formData.profession_type == 1" class="mb-3">
                                          <b-form-checkbox
                                              class="mt-2"
                                              id="not_here_designation"
                                              v-model="formData.not_here_designation"
                                              :disabled="checkDisable('not_here_designation')"
                                              :value=1
                                              :unchecked-value=0
                                          >
                                          <span class="text-danger">{{$t('elearning_iabm.not_here_designation')}}</span>
                                          </b-form-checkbox>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.profession_type === 2 || formData.profession_type === 3">
                                        <ValidationProvider name="Address (En)" vid="office_mail_address_en" rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="office_mail_address_en"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('externalTraining.office_mail')}} {{ $t('globalTrans.enn')}}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                                id="office_mail_address_en"
                                                v-model="formData.office_mail_address_en"
                                                :disabled="checkDisable('office_mail_address_en')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.profession_type === 2 || formData.profession_type === 3">
                                        <ValidationProvider name="Address (Bn)" vid="office_mail_address_bn" rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="office_mail_address_bn"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('externalTraining.office_mail')}} {{ $t('globalTrans.bnn')}}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                                id="office_mail_address_bn"
                                                v-model="formData.office_mail_address_bn"
                                                :disabled="checkDisable('office_mail_address_bn')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.not_here_designation == 1 || formData.profession_type === 2 || formData.profession_type === 3">
                                        <ValidationProvider name="Designation (En)" vid="designation_en" rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="designation_en"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_tim.designation_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="designation_en"
                                            v-model="formData.designation_en"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12" v-if="formData.not_here_designation == 1 || formData.profession_type === 2 || formData.profession_type === 3">
                                        <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="designation_bn"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_tim.designation_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="designation_bn"
                                            v-model="formData.designation_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                    </b-row>
                                  </fieldset>
                                  <fieldset class="p-2 w-100">
                                    <legend class="px-2 w-50 shadow-sm">{{$t('elearning_iabm.training_info')}}</legend>
                                    <b-row class="pt-3 px-3">
                                    </b-row>
                                    <b-row class="p-3">
                                      <b-col lg="12" sm="12">
                                        <p style="font-size: 12px;" class="text-success">{{ $t('globalTrans.note') + ' : ' }}{{ $t('portal.training_note') }}</p>
                                      </b-col>
                                      <b-col lg="6" sm="12">
                                        <ValidationProvider name="Training Title En" vid='training_title_en' rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="training_title_en"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_config.training_title_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="training_title_en"
                                                v-model="formData.training_title_en"
                                                :disabled="checkDisable('training_title_en')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="12">
                                        <ValidationProvider name="Training Title (bn)" vid='training_title_bn' rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="training_title_bn"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_config.training_title_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="training_title_bn"
                                                v-model="formData.training_title_bn"
                                                :disabled="checkDisable('training_title_bn')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="6">
                                      <ValidationProvider name="Start Date" vid="start_date" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="start_date"
                                            slot-scope="{ valid, errors }"
                                        >
                                          <template v-slot:label>
                                          {{ $t('elearning_config.training') }} {{$t('globalTrans.start_date')}} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              class="fromDate"
                                              v-model="formData.start_date"
                                              :placeholder="$t('globalTrans.select_date')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                      </b-col>
                                      <b-col lg="6" sm="6">
                                        <ValidationProvider name="End Date" vid="end_date" rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="end_date"
                                              slot-scope="{ valid, errors }"
                                          >
                                            <template v-slot:label>
                                              {{ $t('elearning_config.training') }} {{$t('globalTrans.end_date')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                class="fromDate"
                                                v-model="formData.end_date"
                                                :placeholder="$t('globalTrans.select_date')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col xs="6" sm="6" md="6" lg="12" xl="6">
                                        <ValidationProvider name="Reason (En)" vid="reason_en" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="reason_en"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{ $t('portal.why_need_duplicate_certificate') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                                id="reason_en"
                                                v-model="formData.reason_en"
                                                :disabled="checkDisable('reason_en')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                                        <ValidationProvider name="Reason (En)" vid="reason_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="reason_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                           {{ $t('portal.why_need_duplicate_certificate') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                                id="reason_bn"
                                                v-model="formData.reason_bn"
                                                :disabled="checkDisable('reason_bn')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                    </b-row>
                                  </fieldset>
                                  <b-row >
                                      <b-col lg="9" sm="12">
                                        <ValidationProvider name="Fee Amount" vid="fee_amount" rules="required">
                                            <b-form-group
                                                class="row"
                                                :label-cols-sm="$i18n.locale === 'bn' ? 4 : 5"
                                                label-for="fee_amount"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('elearning_config.fee_amount')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="name_bn"
                                                v-model="formData.fee_amount"
                                                :disabled="true"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <!-- <p class="text-danger"><b>{{ $t('elearning_iabm.registration_note')}}</b></p>
                                        <p><b>{{ $t('elearning_iabm.registration_note_2')}} (<span class="text-danger">*</span>) {{ $t('elearning_iabm.registration_note_3')}}</b></p> -->
                                      </b-col>
                                  </b-row>
                                </b-overlay>
                                </b-col>
                              </b-row>
                              <div class="text-right">
                                <b-button type="submit" variant="primary" class="mr-2" :disabled="isLoading">
                                  <span v-if="isLoading"><b-spinner small></b-spinner> {{ $t('globalTrans.loading') }}</span>
                                  <span v-else>{{ $t('portal.submit') }}</span>
                                </b-button>
                              </div>
                            </b-form>
                          </ValidationObserver>
                      </b-col>
                    </b-row>
                  </b-overlay>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal ref="mymodal" id="modal-4" size="md" :title="$t('portal.request_duplicate_certificate')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <br/>
        <b-overlay :show="loadModal">
          <ValidationObserver ref="otp"  v-slot="{ handleSubmit, reset }">
            <b-form id="otp" @submit.prevent="handleSubmit(otpCheckData)" @reset.prevent="reset" >
              <b-row>
                <h2 class="col-lg-12 text-center" v-if="parseInt(timer) > 0">{{$t('globalTrans.timer')}} : {{ timer | minutesAndSeconds }}</h2>
                <h2 class="col-lg-12 text-center" v-else>{{$t('portal.please_resent_otp')}}</h2>
                <b-col lg="12" sm="12">
                  <b-col lg="12" sm="12">
                    <ValidationProvider name="Otp"  vid="otp" rules="required">
                      <div slot-scope="{ valid, errors }">
                        <b-form-input
                          id="otp"
                          v-model="otpCheck"
                          :placeholder="$t('globalTrans.otp')"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </b-col>
                </b-col>
                <br/>
                <b-col lg="12" sm="12" style="padding-left: 30px;padding-top:10px">
                  <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.verify') }}</b-button>
                  <!-- <b-button type="button" variant="primary" class="mr-2 lg-3" @click="otpCheckData">{{ $t('globalTrans.verify') }}</b-button> -->
                  <b-button type="button" variant="warning" class="mr-2 lg-3" @click="reOtpSend">{{ $t('globalTrans.resend_code') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      <br/>
    </b-modal>
  </div>
</template>
<style>
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 14px!important;
    padding: 4px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
  .alert .close {
    color: red !important;
  }
</style>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '../../../../config/api_config'
import { duplicateCertificateApiPaymentStatus } from '../../api/routes'
import Common from '@/mixins/portal/common'
import flatpickr from 'flatpickr'
const TWO_MINITES = 60 * 2
const today = new Date().toISOString().substr(0, 10)

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [Common],
  created () {
    // this.getDesignationByOrg()
    if (this.$route.params.status) {
      this.paymentStatusUpdate()
    }
    this.getFeeAmount()
  },
  data () {
    return {
      current_date: today,
      isLoading: false,
      isLoadingData: false,
      load: false,
      loadModal: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('portal.submit'),
      formData: {
        name_en: '',
        name_bn: '',
        email: '',
        mobile: '',
        applied_org_id: 12,
        subject_type_id: 0,
        training_title_en: '',
        training_title_bn: '',
        start_date: '',
        end_date: '',
        reason_en: '',
        reason_bn: '',
        org_id: 0,
        office_type_id: 0,
        office_id: 0,
        not_here_office: 0,
        office_name_en: '',
        office_name_bn: '',
        other_org_name: '',
        other_org_name_bn: '',
        profession_type: 0,
        job_type_id: 0,
        designation_id: 0,
        not_here_designation: 0,
        designation_en: '',
        designation_bn: '',
        office_mail_address_en: '',
        office_mail_address_bn: '',
        batch: '',
        cader_name: '',
        cader_name_bn: '',
        fee_amount: '',
        status: 1
      },
      officeTypeList: [],
      officeList: [],
      designationList: [],
      booking: true,
      venuList: [],
      venueCondition: '',
      otp: '',
      reload: false,
      showMessage: false,
      otpCheck: '',
      venus: [],
      countShow: false,
      TWO_MINITES: TWO_MINITES,
      timer: 0
    }
  },
  filters: {
    minutesAndSeconds (value) {
      var minutes = Math.floor(parseInt(value, 10) / 60)
      var seconds = parseInt(value, 10) - minutes * 60
      return `${minutes}:${seconds}`
    }
  },
  computed: {
    professionTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non Govt. Organization',
          text_en: 'Non Govt. Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ]
    },
    subjectTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'সেমিনার' : 'Seminer',
          text_en: 'Seminer',
          text_bn: 'সেমিনার'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'প্রশিক্ষণ' : 'Training',
          text_en: 'Training',
          text_bn: 'প্রশিক্ষণ'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'কর্মশালা' : 'Workshop',
          text_en: 'Private Company',
          text_bn: 'Workshop'
        }
      ]
    },
    jobTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'ক্যাডার' : 'Cader',
          text_en: 'Cader',
          text_bn: 'ক্যাডার'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'নন-ক্যাডার' : 'Non-Cader',
          text_en: 'Non-Cader',
          text_bn: 'নন-ক্যাডার'
        }
      ]
    },
    orgList: function () {
      return this.$store.state.Portal.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    duplicateCertificateFeeList: function () {
      return this.$store.state.Portal.trainObj.duplicateCertificateFeeList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          const n = obj.text_bn
          if (typeof n === 'string') {
            const num = n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            return { value: obj.value, text: num }
          } else {
            return { value: obj.value, text: '' }
          }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    govOrPvt: function () {
      const govOrPvt = [
        {
          value: 1,
          text: 'govt',
          text_en: 'govt',
          text_bn: 'সরকারী'
        },
        {
          value: 2,
          text: 'private',
          text_en: 'private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
      const tmpList = govOrPvt.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
      return tmpList
    },
    fiscalYearList: function () {
      return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newVal, oldVal) {
      if (newVal !== 0) {
        this.designationList = this.getDesignationByOrg(newVal)
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'formData.office_type_id': function (newValue) {
      if (newValue !== 0) {
        this.officeList = this.getOfficeList(newValue)
      }
    },
    'formData.office_id': function (newValue) {
      this.getVenusList(newValue)
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  methods: {
    async paymentStatusUpdate () {
      if (this.$route.params.status && this.$route.query.transId) {
        const status = this.$route.params.status
        const tranId = this.$route.query.transId
        await RestApi.getData(trainingElearningServiceBaseUrl, duplicateCertificateApiPaymentStatus + status, { transId: tranId }).then(response => {
          if (response.success) {
            this.$toast.success({
              title: 'Success',
              message: response.message,
              color: '#D6E09B'
            })
          } else {
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
        // this.$router.push(this.$route)
      } else {
        // this.$router.push('/training/duplicate-certificate')
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getDesignationByOrg (orgId = null) {
      const desigList = this.$store.state.Portal.commonObj.masterDesignationList.filter(item => item.status === 0)
      if (desigList) {
          return desigList.filter(item => item.org_id === parseInt(orgId)).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return desigList
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.Portal.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getOfficeList (orgId = null) {
      const officeList = this.$store.state.Portal.commonObj.officeList.filter(item => item.status === 0)
      if (orgId) {
        return officeList.filter(office => office.office_type_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeList
    },
    checkDisable (field) {
      const mainData = []
      const data = mainData[field]
      if (data) {
          return true
      }
      return false
    },
    async getFeeAmount () {
      const params = {
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'config/duplicate-certificate-fee/fee-list', params)
      if (!result.success) {
        this.formData.fee_amount = ''
      } else {
        const feeObj = result.data.data
        const fee = feeObj.find(item => item.status === 1 && item.effective_date >= this.current_date)
        this.formData.fee_amount = fee.fee_amount
      }
    },
    async createData () {
      this.load = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, 'external-user/duplicate-certificate-request/store', this.formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.showMessage = true
        this.$router.push('/training/certificate-request-preview/' + result.data.id)
        this.$refs.form.reset()
        this.load = false
        this.otp = ''
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
